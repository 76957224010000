import React, { useEffect, useState } from "react";
// Components
import InputText from "../InputText/InputText";
import Button from "../../Button/Button";
// Constants
import * as BUTTON_CONST from "../../../constants/buttons";
// Styles
import "./TableDynamic.css";
import classNames from "classnames";

const TableDynamic = ({
  disabled,
  columns = [],
  onChange,
  sectionItem,
  value = [],
}) => {
  const [inputValues, setInputValues] = useState({});
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);

  const buttonClasses = classNames(
    {
      disabled: disabled || !allFieldsFilled,
    },
    {
      secondary: !disabled && allFieldsFilled,
    }
  );

  const handleOnChange = (event, columnName) => {
    const value = event.target.value;
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [columnName]: value,
    }));
  };

  const handleAddNewRow = () => {
    const newValue = [...value, inputValues];
    onChange(newValue);
    setInputValues({});
  };

  const handleDeleteClickedRow = (event, index) => {
    event.preventDefault();
    const newValue = value?.filter((_, i) => i !== index);
    onChange(newValue);
  };

  useEffect(() => {
    const isFilled =
      Object.keys(inputValues).length === columns.length &&
      Object.values(inputValues).every((value) => value !== "") &&
      Object.keys(inputValues).length > 0 &&
      columns.length > 0;
    setAllFieldsFilled(isFilled);
  }, [columns.length, inputValues]);

  return (
    <>
      {columns?.map((column, index) => (
        <InputText
          key={index}
          disabled={disabled}
          label={column}
          onChange={(event) => handleOnChange(event, column)}
          placeholder={sectionItem?.placeholder}
          required={sectionItem?.required}
          value={inputValues[column] || ""}
        />
      ))}
      <div className="tableDynamic__wrapper-btn-addNew">
        <span
          onClick={() => !disabled && allFieldsFilled && handleAddNewRow()}
          className={`tableDynamic__btn-addNew ${
            disabled || !allFieldsFilled ? "link__disabled" : ""
          }`}
        >
          {BUTTON_CONST.BUTTON_ADD_NEW_ROW}
        </span>
      </div>
      {value?.length > 0 && (
        <div className="tableDynamic__wrapper">
          <table className="tableDynamic__table">
            <thead className="tableDynamic__thead">
              <tr className="tableDynamic-headerC">
                {columns?.map((column, index) => (
                  <th
                    key={index}
                    scope="col"
                    className={`tableDynamic-columnTitle ${
                      disabled
                        ? "admin__disabled-color tableDynamic__rowData-disabled"
                        : null
                    }`}
                  >
                    {column}
                  </th>
                ))}
                {!disabled && (
                  <th
                    scope="col"
                    className={`tableDynamic-columnTitle-action`}
                  ></th>
                )}
              </tr>
            </thead>
            <tbody className="tableDynamic__body-wrapper">
              {value?.map((item, index) => (
                <tr key={index} className="tableDynamic__body-tr">
                  {Object.entries(item).map(
                    ([column, columnValue], columnIndex, columnsArray) => (
                      <td
                        className={`tableDynamic__rowData ${
                          disabled
                            ? "tableDynamic__rowData-disabled admin__disabled-color"
                            : null
                        }`}
                        key={columnIndex}
                      >
                        <div>{columnValue}</div>
                      </td>
                    )
                  )}
                  {!disabled && (
                    <td className="tableDynamic__btn-action">
                      <Button
                        classes={disabled ? "disabled" : "secondary"}
                        disabled={disabled}
                        onClick={(event) =>
                          handleDeleteClickedRow(event, index)
                        }
                        style={{ width: "fit-content", minHeight: "100%" }}
                        title="Delete"
                      />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default TableDynamic;
