import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Components
import AsideContext from "../../context/AsideMenu/AsideContext";
// Icons
import CloseMenu from "../../assets/icon_double_left.png";
import OpenMenu from "../../assets/icon_double_right.png";
import AdminDashboard from "../../assets/admin_icon_dashboard.png";
import AdminSettings from "../../assets/admin_icon_settings.png";
import AdminBuilder from "../../assets/admin_icon_builder.png";
import logoRCXSports from "../../assets/logo_rcxSports.png";
// Constants
import * as ADMIN_CONST from "../../constants/adminPage";
// Css
import "./AsideMenu.css";

const getIconImageFromName = (name) => {
  switch (name) {
    case "Dashboard":
      return AdminDashboard;
    case "Builder":
      return AdminBuilder;
    case "Settings":
      return AdminSettings;
    default:
      return "";
  }
};

const AsideMenu = ({ selectedIcon }) => {
  const { asideOpen, toggleAside } = useContext(AsideContext);
  const [iconSelected, setIconSelected] = useState(selectedIcon);
  const navigate = useNavigate();

  const getURLfromName = (name) => {
    if (name === "Dashboard") {
      return navigate(`/admin/dashboard`);
    }
    if (name === "Builder") {
      return navigate(`/admin/builder`);
    }
    if (name === "Settings") {
      return navigate(`/admin/settings/programs`);
    }
  };

  const handleOnClickIcon = (icon, index) => {
    if (iconSelected === index) {
      if (!asideOpen) return;
    } else {
      setIconSelected(index);
    }
    return getURLfromName(icon);
  };

  useEffect(() => {
    if (
      typeof selectedIcon !== "number" ||
      isNaN(selectedIcon) ||
      selectedIcon < 0
    )
      return;
    setIconSelected(selectedIcon);
  }, [selectedIcon]);

  return (
    <>
      {typeof selectedIcon === "number" &&
        !isNaN(selectedIcon) &&
        selectedIcon >= 0 && (
          <aside
            className={asideOpen ? "admin__aside-expanded" : "admin__aside"}
          >
            <div className="admin__aside-topMenu" onClick={toggleAside}>
              {asideOpen ? (
                <div className="admin__aside-topMenuOpen">
                  <img
                    src={logoRCXSports}
                    alt="Logo RCX Sports Top Bar"
                    style={{ maxWidth: "136px" }}
                  />
                  <img src={CloseMenu} alt="Close Menu" />
                </div>
              ) : (
                <img src={OpenMenu} alt="Open Menu" />
              )}
            </div>
            <div
              className={`${
                asideOpen
                  ? "admin__aside-iconsListExpanded"
                  : "admin__aside-iconsList"
              }`}
            >
              {ADMIN_CONST.ASIDE_LIST_ICONS?.map((icon, index) => {
                return (
                  <div
                    onClick={() => handleOnClickIcon(icon, index)}
                    key={index}
                    className={`${
                      asideOpen
                        ? "admin__aside-iconExpanded"
                        : "admin__aside-icon"
                    } ${
                      iconSelected === index ? "icon-active" : "icon-iactive"
                    }`}
                  >
                    <img src={getIconImageFromName(icon)} alt={icon} />
                    {asideOpen ? (
                      <span className="admin__aside-iconActiveText">
                        {icon}
                      </span>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </aside>
        )}
    </>
  );
};

export default AsideMenu;
