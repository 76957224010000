export const ADMIN_PAGES = Object.freeze({
  builder: "builder",
  dashboard: "dashboard",
  programs: "programs",
  settings: "settings",
});
export const ASIDE_ICON_DASHBOARD = "Dashboard";
export const ASIDE_LIST_ICONS = ["Dashboard", "Builder", "Settings"];
export const TOPBAR_DASHBOARD_TITLE = "Admin dashboard";
export const TOPBAR_SEARCH_RESULTS = "Search results";
export const ORG_CURRENT_STATUS = "Organization's current status";
export const ORG_ONBOARDING_SENT = "Onboarding sent";
export const ORG_ONBOARDING_LAST_UPD = "Onboarding Last update";
export const ORG_LEAGUE_CONTACT = "League contact";
export const DASHBOARD_TITLE = "Organizations";
export const HEADER_COLUMNS = [
  { id: "0", name: "Client", width: "18%" },
  { id: "1", name: "Program", width: "10%" },
  { id: "2", name: "Status", width: "14%" },
  { id: "3", name: "Location", width: "16%" },
  { id: "4", name: "Version", width: "10%" },
  { id: "5", name: "Flowtype", width: "16%" },
  { id: "6", name: "Date Added", width: "16%" },
];
//
export const ORG_NO_INFORMATION = "No information has been added yet";
export const ORG_NO_INFORMATION_DESC =
  "This organization hasn't updated their onboarding information yet, you'll receive a notification when any updates are ready for you to review.";
export const CONTACT_ORGANIZATION = "Contact Organization: ";
export const ORG_ASK_APPROVE_SECTION =
  "Sure you want to changes status for this section?";
export const ORG_ASK_APPROVE_SECTION_DESC =
  "This will replace current section status";
export const ORG_ASK_APPROVE_ALL = "Sure you want to mark all as approved?";
export const ORG_ASK_APPROVE_ALL_DESC =
  "There are still some items pending a revision on this section, approving them all would override those.";
export const ORG_ASK_CHANGE_STATUS =
  "You are about to change the organization status";
export const ORG_ASK_CHANGE_STATUS_DESC =
  "Changing the status of this organization will override the existing values and change its status.";
export const ORG_ASK_APPROVE_ORG =
  "Sure you want to approve this organization?";
export const ORG_ASK_APPROVE_ORG_DESC =
  "There are still some items pending a revision, marking this org as approved will override those and approve them all.";
export const ORG_ASK_APPROVE_FIELD = "Sure you want to update this field?";
export const ORG_ASK_APPROVE_FIELD_DESC =
  "This will replace previous content another user has created on this form.";
export const NEW_FLOW_SENT_TITLE = "New flow will be sent to organization";
export const NEW_FLOW_SENT_DESCRIPTION =
  "Flow will remain in pending untill the organization completes it";
export const TITLE_FILTERS = "Filters";
export const TITLE_MODAL_SEND_NEW_FLOW = "Send new flow to Organization";
export const TITLE_CHAT = "Chat";
export const LIST_PROGRAM_TYPE = ["STREET", "NBA", "NHL", "NFL", "MLS"];
export const LIST_FLOW_TYPE = [
  "Jr NBA 2022",
  "Onboarding",
  "Insurance",
  "New Flow",
];
export const FILTER_PROGRAM = [
  { name: "NHL Street", value: "STREET" },
  { name: "NFL Flag", value: "NFL" },
  { name: "JR NBA", value: "NBA" },
  { name: "MLS Go", value: "MLS" },
];
export const FILTER_DATES = [
  { name: "Today", value: "Today" },
  { name: "Last 7 days", value: "Last 7 days" },
  { name: "Last 30 days", value: "Last 30 days" },
  { name: "", value: "datePicker" },
];
export const FILTER_TITLE_STATUS = "Status";
export const FILTER_TITLE_PROGRAM = "Program";
export const FILTER_TITLE_DATE = "Date Range";
export const FILTER_TITLE_FROM = "From";
export const FILTER_TITLE_TO = "To";
export const LAST_UPDATED_BY = "Last updated by:";
export const UNKNOWN = "unknown";
export const CARD_ALL_ORG = "All organizations";
export const CARD_ACTIVE_ORG = "Active organizations";
export const CARD_PENDING_TASKS = "Total pending tasks";
export const LAST_MONTH = "LAST MONTH";
export const LAST_WEEK = "LAST WEEK";
export const PLACEHOLDER_SEARCH = "Search";
export const NOT_AVAILABLE = "Not Available";
export const UNNAMED_ORGANIZATION = "Unnamed Organization";
export const LEAVE_WITHOUT_SAVING = "You are about to lose your progress.";
export const LEAVE_WITHOUT_SAVING_DESC =
  "You will be able to complete it later.";
export const LABEL_EMAIL_SEND_NEW_FLOW =
  "Enter organization's email(s), separated by commas";
/* "Write the email linked to the organization"; */
export const SEARCH_RESULT_FOUND = "Search Results found";
export const LABEL_PROGRAM_SEND_WORKFLOW = "Program";
export const LABEL_FLOW_SEND_WORKFLOW = "Flow";
export const PLACEHOLDER_EMAIL_WORKFLOW = "example1@gmail.com, example2@gmail.com";
export const MODAL_TITLE_PROGRAM = "Program";
