import React from "react";
import Avatar from "../../../Avatar/Avatar";
// Constants
import * as BUILDER_CONST from "../../../../constants/builderPage";

const AdminBuilderHeader = () => {
  return (
    <div className="admin__topBar">
      <div className="admin__topBar-title">
        {BUILDER_CONST.ONBOARDING_FLOWS}
      </div>
      <div className="admin__topBar-right">
        <Avatar typeUser={"admin"} />
      </div>
    </div>
  );
};

export default AdminBuilderHeader;
